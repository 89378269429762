<template>
  <div 
    id="login"
    class="home d-flex justify-content-center align-items-center"
  >
    <b-container
      class="d-flex justify-content-center align-items-center"
    >
    <b-row>
      <b-col>
        <p>
          Laëtitia <span class="sheLovelyFont">et</span> Pierre
        </p>
        <b-form-input
          v-model="password"
          type="password"
          class="m-auto w-75"
          placeholder="Mot de passe"
        ></b-form-input>
        <b-button
          class="m-2 next"
          @click.stop="login()"
        >
          Go
        </b-button>
      </b-col>
    </b-row>
    </b-container>
  </div>
</template>

<script>
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css"
import axios from "axios";

export default {
  data() {
    return {
      password: null
    }
  },
  methods: {
    login() {
      axios
        .post(`https://api.notremariage.love/login`, {
          password: this.password,
        })
        .then((response) => {
          this.password = "";
          localStorage.setItem("access-token", response.data);
          this.$router.push("/");
        })
        .catch((error) => {
          Toastify({
            text: error.response.data,
            duration: 4000,
            newWindow: true,
            close: true,
            gravity: "bottom",
            position: "right",
            backgroundColor: "#f69193",
          }).showToast();
        });
    },
  }
}
</script>
<style lang="scss">
#login {
  background-image: url("../assets/fond.jpg") !important;
  background-size: cover;
  background-position: 90%;
  width: 100%;
  height: 100vh;
  p {
    font-size: 3rem;
    color: #3A5F7E;
  }
}
</style>
